/**
 * @param {Object} obj 字符串
 * @returns {Array}
 */
export function objToOptions(obj) {
  console.log(obj, 222)
  const arr = []
  if (obj) {
    for (const k in obj) {
      arr.push({
        label: obj[k],
        value: k
      })
    }
  }
  return arr
}

export function validatePasswordLetter(v = '') {
  const pattern = [
    'a-zA-Z0-9',
    '`\\', // backtick `, backslash \
    `!"#$%&'()*+,-.:;<=>?@[]^_{|}~/`.replace(/[\[\]\-\\]/g, (m) => '\\' + m), // halfwidth
    `！“”＃＄￥％＆‘’（）〔〕＊＋，、－−。：；？＠＝《》〈〉【】『』「」﹃﹄＿﹏…～／＼` // fullwidth
  ].join('')

  return new RegExp(`^[${pattern}]*$`).test(v)
}

export function validatePasswordLength(v = '') {
  return /^.{6,16}$/.test(v)
}

/**
 * @description chinese word validation
 * @param {String} param 字符串
 * @returns {Boolean}
 */
export function validateChinese(param) {
  const regChineseSpecial = new RegExp('[\u4E00-\u9FA5]+')
  return !regChineseSpecial.test(param)
}

/**
 * @description username validation
 * @param {String} param 字符串
 * @returns {Boolean}
 */
export function validateUsername(param) {
  return /^[a-zA-Z0-9]{6,16}$/.test(param)
}

/**
 * @description three dot validation
 * @param {string} param
 * @returns
 */
export function validateThreeDot(param) {
  const regThreeDot = /…/
  return !regThreeDot.test(param)
}

/**
 * @description email validation
 * @param {String} param 字符串
 * @returns {Boolean}
 */
export function validateEmail(param) {
  const reg =
    /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/
  return reg.test(param)
}
